
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '@/components/TitleReturn.vue';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import ShopModule from '@/store/shops';
import ShopEntityModule from '@/store/shops/entity';

@Component({
  components: {
    TitleReturn,
    FormBlock,
    Loading,
  },
})
export default class ShopEntityAdd extends Form {
  isLoading = true;

  get titlePage(): string {
    return ShopModule.pageSettings.titleAdd;
  }

  returnBefore(): void {
    this.$router.push({ name: 'shops' });
  }

  async mounted(): Promise<void> {
    await ShopEntityModule.initAdd();
    this.isLoading = false;
  }
}
